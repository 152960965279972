define("ember-concurrency-ts/index", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.perform = perform;
  _exports.taskFor = taskFor;
  function taskFor(task) {
    (true && !(task && (typeof task === 'function' || typeof task.perform === 'function')) && (0, _debug.assert)(`${task} does not appear to be a task!`, task && (typeof task === 'function' || typeof task.perform === 'function')));
    return task;
  }
  function perform(task, ...args) {
    (true && !(task && typeof task.perform === 'function') && (0, _debug.assert)(`${task} does not appear to be a task!`, task && typeof task.perform === 'function'));
    return task.perform(...args);
  }
});