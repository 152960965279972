define("ember-svg-jar/inlined/safari-pinned-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M71.6 1.5C37.3 8.4 11.7 32.4 2.8 66L.5 74.5v551l2.3 8.5c8.3 31.5 31.7 54.9 63.2 63.2l8.5 2.3h551l8.5-2.3c31.5-8.3 54.9-31.7 63.2-63.2l2.3-8.5v-551l-2.3-8.5c-4.3-16.3-12.5-30.3-24.3-41.6-11.2-10.6-23.1-17.3-38.4-21.6l-8-2.3-274-.2C124 .2 77.4.4 71.6 1.5zm293.1 202.1c23.8 6.2 42.1 31 39.9 53.9-.4 3.8-1.1 13.5-1.5 21.5-.7 12.4-1.3 15.8-4 23.4-1.7 5-3.3 11.9-3.6 15.5-1.1 12.5-3.5 18.8-11.4 30.6l-4.1 6v29.6l4.8 4.7c5.6 5.6 14.9 10.3 32.7 16.8 19.9 7.1 28.3 11.9 38.3 21.6 9.3 9 12.9 17.4 10.3 24-1.2 3.1-26.3 29.1-34.8 36-3.7 3.1-8.6 6.5-10.9 7.6-7.9 4-14 4.3-77.4 4-66.1-.4-62.6-.1-73-6.6-6.1-3.8-38.3-35.1-40.6-39.4-4.6-8.7 1.1-19.9 15.9-31.2 9.1-6.9 18.1-11.4 32.8-16.4 18.7-6.5 31-13.4 34.9-19.8 1.7-2.7 2-5 2-16.6v-13.4l-5.8-9.2c-6.5-10.1-8.8-16.3-9.7-26.7-.4-3.9-2.2-11.7-4.1-17.4-3.3-9.8-3.5-11.4-4.5-32.2-1-19.4-.9-22.4.6-27.6 3.8-12.9 12.9-25.5 23.4-32.2 12.8-8.3 33-10.9 49.8-6.5z\"/>",
    "attrs": {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "933.333",
      "height": "933.333",
      "viewBox": "0 0 700 700"
    }
  };
});